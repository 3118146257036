<template>
  <v-btn
    @click="btn_function"
    class="btn"
    style="z-index: 10; color: var(--white)"
    color="primary"
    :loading="loading"
    ><span class="btn-title">
      {{ title }}
    </span>
  </v-btn>
</template>
<style scoped>
.btn:focus {
  box-shadow: 0px 4px 22px var(--primary);
}
.btn {
  font-weight: bold;
  color: var(--white) !important;
  background: var(--primary) !important;
  border-radius: 16px;
  box-shadow: none;
  width: 244px;
  height: 54px !important;
}
.btn:hover {
  background-color: var(--primary-hover) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}
.btn:active {
  background-color: var(--primary-focus) !important;
}
.btn-title {
  font-size: 24px;
}
</style>
<script>
export default {
  name: "DxaButton",
  props: ["title", "loading"],
  methods: {
    btn_function() {
      this.$emit("function");
    },
  },
};
</script>
