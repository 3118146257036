<template>
  <div
    id="calendly-finish-container"
    class="flexing d-flex justify-end"
    style="
      margin-bottom: 0px;
      min-height: 100vh;
      padding-top: 75px;
      background-color: var(--dark);
    "
  >
    <div class="nav-bar">
      <v-card
        class="ma-0 pa-0"
        style="
          position: absolute;
          top: 10px;
          left: 10%;
          display: flex;
          flex-direction: row;
          background-color: var(--dark);
          box-shadow: none !important;
        "
        elevation="0"
      >
        <img
          class="logo"
          src="../../../assets/DXA_Invest-logo.png"
          alt="logo"
        />
      </v-card>
    </div>
    <div class="text-content">
      <span class="portfolio-created">
        {{ $t(`portfolio_created_successfully`) }}
      </span>
      <br />

      <p
        style="
          color: var(--white);
          font-size: 14px;
          margin-top: 15px;
          width: 290px;
        "
        id="portfolio-message"
      >
        {{ $t(`portfolio_created_message`) }}
      </p>
      <p style="color: var(--primary); font-size: 16px; font-weight: bold">
        {{ $t("about_your_checkin") }}
      </p>

      <div class="about-checkin-section">
        <span
          class="about-checkin-label"
          v-for="(detail, index) in checkinDetails"
          :key="index"
        >
          <v-icon small class="check-icon" color="primary">mdi-check</v-icon>
          {{ $t(detail.name) }}
        </span>
      </div>

      <br />
      <p style="color: var(--primary); font-size: 16px; font-weight: bold">
        {{ $t("next_step") + ":" }}
      </p>
      <p>
        <v-icon color="primary" class="text-content-icon"
          >mdi-checkbox-blank-circle</v-icon
        >
        <span style="color: var(--white); font-weight: 700">{{
          $t("your_first_check_in")
        }}</span>
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white" class="text-content-icon"
          >mdi-calendar-blank-outline</v-icon
        >
        {{ parseDate(date) }}
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white" class="text-content-icon"
          >mdi-timer-outline</v-icon
        >
        {{ formatHour(hour) }}
      </p>

      <p
        style="color: var(--primary); margin-bottom: 12px; font-size: 12px"
        id="invite-send-email"
      >
        {{ $t("invite_send_to_email") }}
      </p>
      <span class="finish-footer">
        {{ $t("alpha_finish_footer") }}
      </span>
      <DxaButton
        @function="goToPipeline"
        :title="$t('Access_dxa')"
        style="width: 290px"
        id="dxaButton"
      ></DxaButton>
      <br />
    </div>
    <v-img
      v-if="!gs.isMobile()"
      style="position: absolute; top: 0; left: 0; z-index: 1 !important"
      max-width="85%"
      src="@/assets/dxa-background-welcome-page2.png"
    ></v-img>
    <v-img
      v-if="!gs.isMobile()"
      style="position: absolute; bottom: 25px; left: 25px"
      max-width="45%"
      src="@/assets/dxa-background-welcome-page3.png"
    ></v-img>
  </div>
</template>

<style scoped>
#dxaButton {
  margin-top: 20px;
}

.finish-footer {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--white);
}

.portfolio-created {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--white);
}

.about-checkin-section {
  display: flex;
  flex-direction: column;
}

.about-checkin-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--white);
  margin: 10px 10px 10px 0;
}

.v-application.mt-10 {
  margin-top: 4px !important;
}

.btn_back {
  width: 125px !important;
  height: 42px !important;
}

.btn_next {
  width: 125px !important;
  height: 42px !important;
}

.finish {
  z-index: 100;
}

.nav-bar {
  background-color: var(--dark);
  height: 70px;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.text-content {
  max-width: 500px;
  padding-right: 10%;
  padding-bottom: 20px;
}

.text-content-icon {
  margin-right: 0.5rem;
}

.logo {
  width: 200px;
}

@media only screen and (max-width: 768px) {
  .portfolio-created {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: var(--white);
  }
  .text-content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 0;
    margin: 20px auto;
    padding: 0 1.25rem;
  }

  #dxaButton {
    width: 300px !important;
  }

  #portfolio-message,
  #invite-send-email {
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 324px) {
  #dxaButton {
    max-width: 290px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .text-content {
    max-width: 520px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
import { getKeyByValue } from "@/shared/helpers/objectHelper";

export default {
  name: "FinishCalendly",
  components: {
    DxaButton,
  },
  filters: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    termsOpened: false,
    terms: false,
    lazy: false,
    date: null,
    alert: null,
    error: null,
    investorCreated: null,
    date: null,
    hour: null,
    checkinDetails: [
      {
        name: "alpha_register_plan_investment",
      },
      {
        name: "alpha_allocation_strategy",
      },
      {
        name: "alpha_profile_mapping",
      },
      {
        name: "alpha_video_call",
      },
      {
        name: "alpha_personal_manager",
      },
    ],
  }),
  async created() {
    this.getInvestorCreated();
  },
  props: {
    Portfolios: Array,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    parseDate(date) {
      moment.locale("pt-br");
      if (!date) return null;
      let adjustDate = moment(date).format("LLLL");
      adjustDate = adjustDate.split("à");
      return adjustDate[0];
    },
    formatHour(hour) {
      return moment(hour).format("LT");
    },
    async getInvestorCreated() {
      this.apiService
        .getRequest(`user/get-calendly-from-user/`)
        .then((response) => {
          this.investorCreated = response.content;
          this.hour = this.investorCreated;
          this.investorCreated = this.investorCreated.split("T");
          this.date = this.investorCreated[0];
        })
        .catch((error) => {
          return error;
        });
    },
    goToPipeline() {
      this.$router.push({ name: "Pipeline" });
      this.getUserLang();
    },
    getUserLang() {
      this.apiService
        .getRequest("user/language")
        .then((response) => {
          let language = getKeyByValue(LanguageEnum, response.content);
          this.$store.commit("SET_LANGUAGE", language);
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
  },
};
</script>
